import {BASE_PATH, PATH_BASE_EXTENSION_ADMIN} from "../../utils/constants";
import axios from "axios";

const baseUrl = `${BASE_PATH}${PATH_BASE_EXTENSION_ADMIN}`;

export function apiLogMail(props) {
    const config = {headers: { 'Authorization': `Bearer ${localStorage.getItem("token")}`}};
    let query = "";
    if (props.mailTo) { query += `&mailTo=${props.mailTo}`; }

    return axios.get(`${baseUrl}/log-mail/list?limit=${props.limit}&offset=${props.offset}&field=${props.field}&sort=${props.sort}${query}`, config)
}

export function apiLogNetSuite(props) {
    const config = {headers: { 'Authorization': `Bearer ${localStorage.getItem("token")}`}};
    let query = "";
    if (props.email) { query += `&email=${props.email}`; }
    if (props.companyName) { query += `&companyName=${props.companyName}`; }
    if (props.transactionGroupNumber) { query += `&transactionGroupNumber=${props.transactionGroupNumber}`; }
    if (props.customerRefPo) { query += `&customerRefPo=${props.customerRefPo}`; }

    return axios.get(`${baseUrl}/log-netsuite/list?limit=${props.limit}&offset=${props.offset}&field=${props.field}&sort=${props.sort}${query}`, config)
}

export function apiLogCeline(props) {
    const config = {headers: { 'Authorization': `Bearer ${localStorage.getItem("token")}`}};
    let query = "";
    if (props.email) { query += `&email=${props.email}`; }
    
    return axios.get(`${baseUrl}/log-celine/list?limit=${props.limit}&offset=${props.offset}&field=${props.field}&sort=${props.sort}${query}`, config)
}

export function apiLogActivity(props) {
    const config = {headers: { 'Authorization': `Bearer ${localStorage.getItem("token")}`}};
    let query = "";
    if (props.email) { query += `&email=${props.email}`; }
    
    return axios.get(`${baseUrl}/log-activity/list?limit=${props.limit}&offset=${props.offset}&field=${props.field}&sort=${props.sort}${query}`, config)
}

