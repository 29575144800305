import React, {useState, useEffect, useMemo} from "react"
import {useParams, useHistory} from "react-router-dom"
import { apiGetTransactionGrouping, transferApi, deleteApi, deleteTransactionApi } from "../../../../services/admin/transactionGrouping"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import TableBase from "../../../../components/Admin/TableBase"
import moment from "moment"
import Loading from "../../../../components/User/Loading"
import InputButtonLoading from "../../../../components/Inputs/InputButtonLoading";
import MsgError from "../../../../components/Admin/MsgError";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Tooltip
} from "@mui/material";

export default function Edit() {
    const {id} = useParams()
    const history = useHistory()

    // Messages
    const [msgSuccess, setMsgSuccess] = useState(null)
    const [msgDanger, setMsgDanger] = useState(null)

    // Loading
    const [loading, setLoading] = useState(true)
    const [loadingNetSuite, setLoadingNetSuite] = useState(false)
    const [loadingDelete, setLoadingDelete] = useState(false)

    // Rows
    const [productos, setProducts] = useState([])
    const [details, setDetails] = useState({})
    const [transactionList, setTransactionList] = useState({})

    // Dialog
    const [open, setOpen] = useState(false);
    const [deleteTransactionGroupingId, setDeleteTransactionGroupingId] = useState("");

    const handleClickOpen = (e) => {
        setMsgDanger(null)
        if (details?.netsuiteTrackingNumber) {
            setMsgDanger(["This Grouping has already been transferred to NetSuite, so the associated order cannot be deleted."])
            return false
        }
        setDeleteTransactionGroupingId(e.target.id)
        setOpen(true);
    };

    const handleClose = () => {
        console.log("close")
        setDeleteTransactionGroupingId(false);
        setOpen(false);
    };

    const handleConfirm = () => {
        deleteTransactionGrouping()
        handleClose()
    };

    const columns = useMemo(
        () => [
            {
                Header: "REFERENCE",
                accessor: "sort",
            },
            {
                Header: "NAME",
                accessor: "name",
            },
            {
                Header: "DESCRIPTION",
                accessor: "description",
            },
            {
                Header: "WOT ORDER N",
                accessor: "transactionId",
            },
            {
                Header: "QUANTITY",
                accessor: "quantity",
            },
            {
                Header: "UNIT PRICE",
                accessor: "priceUnit",
            },
            {
                Header: "TOTAL",
                accessor: "totalPrice",
            },
            {
                Header: "CURRENCY",
                accessor: "currencyName",
            }
        ],
        []
    )

    const transferExec = () => {
        setLoadingNetSuite(true)
        setMsgSuccess(null)
        setMsgDanger(null)
        transferApi({idTransactionProductGrouping: details?.id}).then(resp => {
            if (resp?.data?.errors.length > 0) {
                setMsgDanger(["It has not been possible to send the data, consult the logs for more information", ...resp?.data?.errors])
            } else {
                // setDetails({...details,
                //     netsuiteTransferDate: resp?.data.data?.netsuiteTransferDate,
                //     netsuiteTrackingNumber: resp?.data.data?.netsuiteTrackingNumber,
                // })
                setMsgSuccess(resp.data?.msg)
                getData()
                window.location.reload();
            }
        }).catch(err => {
            setMsgDanger(err.response.data?.msg)
        }).finally(() => {
            setLoadingNetSuite(false)
        })
    }

    const deleteGrouping = () => {
        setLoadingDelete(true)
        setMsgSuccess(null)
        setMsgDanger(null)
        deleteApi(details?.id).then(resp => {
            history.push("/admin/transaction/management")
        }).catch(err => {
            setMsgDanger(err.response.data?.msg)
        }).finally(() => {
            setLoadingDelete(false)
        })
    }

    const deleteTransactionGrouping = () => {
        setLoadingDelete(true)
        setMsgSuccess(null)
        setMsgDanger(null)
        if (!details?.id || !deleteTransactionGroupingId) {
            return false;
        }
        deleteTransactionApi(details?.id, deleteTransactionGroupingId).then(resp => {
            getData()
        }).catch(err => {
            setMsgDanger(err.response.data?.msg)
        }).finally(() => {
            setLoadingDelete(false)
        })
    }

    const getData = async () => {
        setLoading(true)
        apiGetTransactionGrouping(id).then(response => {
            let detailsResponse = response.data.data.details
            let transactionListResponse = response.data?.data.transactionList

            if (detailsResponse.creationDate) {
                detailsResponse.creationDate = moment.unix(detailsResponse.creationDate).utc().format('DD-MMM-YYYY HH:mm A')
            }

            setDetails(detailsResponse)
            setTransactionList(transactionListResponse)
            // get Products
            let currencyName = ''
            const products = response.data.data.products.map((doc) => {
                if (doc.currencyName) {
                    currencyName = doc.currencyName;
                }
                if (!doc.currencyName) {
                    doc.currencyName = currencyName;
                }
                if (!doc.quantity) {
                    doc.quantity = "";
                }

                return {
                    ...doc,
                }
            })
            setProducts(products)
            setLoading(false)

        }).catch(err => {
            setLoading(false)
        })
    }

    // Llamamos a la API para obtener los datos del usuario
    useEffect(() => {
        getData()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Delete Associated Order"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Removing this order will delete ALL products associated with it.<br />
                        If this Grouping is summarized, it will in turn subtract quantities from the products already in the Grouping without deleting them.
                        { deleteTransactionGroupingId && (
                            <>
                                <br/>
                                <span>
                                    If you want to check the order contents, 
                                    <a href={`/admin/transaction/edit/${deleteTransactionGroupingId}/1`} target="_blank" rel="noreferrer"> click here</a>.
                                </span>
                            </>
                        )}
                        <br />
                        This operation cannot be reverted.
                        <br/><br />
                        Are you sure you wish to delete the associated order?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button onClick={handleConfirm}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            <HeaderPage title="Display Order Grouping">
                {!loading && (
                    <>
                    <InputButtonLoading
                        onClick={transferExec}
                        loading={loadingNetSuite}
                        label="Transfer to NetSuite"
                        variant="outlined"
                        disabled={details.netsuiteTrackingNumber}
                    />

                    <InputButtonLoading
                        onClick={deleteGrouping}
                        loading={loadingDelete}
                        label="Delete Grouping"
                        variant="outlined"
                        disabled={details.netsuiteTrackingNumber}
                        style={{marginLeft:2, marginRight:2}}
                    />
                    </>
                )}
            </HeaderPage>

            {msgSuccess && (
                <Grid item xs={12} md={12} lg={12}>
                    <Box className="alert-success">
                        {msgSuccess}
                    </Box>
                </Grid>
            )}

            {msgDanger && (
                <Grid item xs={12} md={12} lg={12}>
                    <MsgError msg={msgDanger} />
                </Grid>
            )}

            {loading ? (
                <Grid item xs={12} md={12} lg={12}>
                    <Loading show={loading}/>
                </Grid>
            ) : (
                <>
                    <Grid container spacing={3}>

                        <Grid item xs={12} md={12} lg={12}>
                            <Box border={1} className="p-20 box-default">
                                <Grid container spacing={3}>
                                    <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        lg={12}
                                        className="border-bottom"
                                    >
                                        <h5 className="p-0 mt-0">Grouping details</h5>
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={6}>
                                        <ul className="MuiList-root css-1mk9mw3-MuiList-root" style={{padding: 0}}>
                                            <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root"
                                                style={{listStyleType: 'none'}}>
                                                <div
                                                    className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                                    <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">
                                                        Company
                                                    </h6>
                                                    <div className="MuiBox-root css-1clcuvi">
                                                        <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">
                                                            {details.companyName}
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root"
                                                style={{listStyleType: 'none'}}>
                                                <div
                                                    className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                                    <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">
                                                        Order Group Number
                                                    </h6>
                                                    <div className="MuiBox-root css-1clcuvi">
                                                        <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">
                                                            {details.transactionGroupNumber}
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root"
                                                style={{listStyleType: 'none'}}>
                                                <div
                                                    className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                                    <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">
                                                        Customer PO/Ref.
                                                    </h6>
                                                    <div className="MuiBox-root css-1clcuvi">
                                                        <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">
                                                            {details.customerRefPo ? details.customerRefPo : '-'}
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>

                                            <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root"
                                                style={{listStyleType: 'none'}}>
                                                <div
                                                    className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                                    <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">
                                                        Associated Orders
                                                    </h6>
                                                    <div className="MuiBox-root css-1clcuvi">
                                                        {transactionList.map((doc, key) => {
                                                            return (
                                                                <>
                                                                    <Tooltip title="Delete from Grouping" placement="bottom">
                                                                        <Button
                                                                            size="small"
                                                                            variant="outlined"
                                                                            id={doc}
                                                                            onClick={handleClickOpen}
                                                                            style={{marginLeft:1, marginRight:1}}
                                                                            disabled={details?.netsuiteTrackingNumber}
                                                                        >
                                                                            {doc}
                                                                        </Button>
                                                                    </Tooltip>
                                                                </>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </li>

                                        </ul>
                                    </Grid>

                                    <Grid item xs={12} md={6} lg={6}>
                                        <ul className="MuiList-root css-1mk9mw3-MuiList-root" style={{padding: 0}}>
                                            <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root"
                                                style={{listStyleType: 'none'}}>
                                                <div
                                                    className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                                    <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">
                                                        Creation Date
                                                    </h6>
                                                    <div className="MuiBox-root css-1clcuvi">
                                                        <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">
                                                            {details.creationDate}
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root"
                                                style={{listStyleType: 'none'}}>
                                                <div
                                                    className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                                    <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">
                                                        Quantity
                                                    </h6>
                                                    <div className="MuiBox-root css-1clcuvi">
                                                        <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">
                                                            {details.quantity}
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root"
                                                style={{listStyleType: 'none'}}>
                                                <div
                                                    className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                                    <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">
                                                        Total
                                                    </h6>
                                                    <div className="MuiBox-root css-1clcuvi">
                                                        <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">
                                                            {details.totalPrice}
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root"
                                                style={{listStyleType: 'none'}}>
                                                <div
                                                    className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                                    <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">
                                                        NetSuite Transfer Date
                                                    </h6>
                                                    <div className="MuiBox-root css-1clcuvi">
                                                        <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">
                                                            {details.netsuiteTransferDate ? (<>{moment.unix(details.netsuiteTransferDate).utc().format('DD-MMM-YYYY HH:mm A')}</>) : '-'}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                                    <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">
                                                        NetSuite Tracking Number
                                                    </h6>
                                                    <div className="MuiBox-root css-1clcuvi">
                                                        <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">
                                                            {details.netsuiteTrackingNumber ? details.netsuiteTrackingNumber : '-'}
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            <Box border={1} className="box-default">
                                <TableBase
                                    columns={columns}
                                    data={productos}
                                    selectedRows={productos}
                                    onSelectedRowsChange={setProducts}
                                    search={false}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
}
