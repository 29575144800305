import Basic from "../../../containers/Admin/Layouts/Basic"
import LogActivity from "../../../containers/Admin/Log/LogActivity"

export default function Management() {
    return (
        <Basic menu="logActivity">
            <LogActivity />
        </Basic>
    )
}
